import React from 'react';
import { ApiClient } from '../../api';
import PaymentComponent from '../../components/payments';
import { useInfiniteQuery } from 'react-query';
import { Columns, Columns2, Columns3, Columns4 } from './data';

const PaymentContainer = () => {
    const [activeTab, setActiveTab] = React.useState<string>('deposits');
    const [search, setSearch] = React.useState<string>('');
    const { data, isFetching, fetchNextPage, fetchPreviousPage, isFetchingNextPage, isFetchingPreviousPage } =
        useInfiniteQuery<Pagination<MPesaTransactions>>(['payments', activeTab, search], ApiClient.getTransactions, {
            refetchOnWindowFocus: false,
            getNextPageParam: (lastPage) => {
                return lastPage.nextPage;
            },
            enabled: activeTab === 'deposits' || activeTab === 'withdrawals',
        });

    const {
        data: paybillData,
        isFetching: isFetchingPaybill,
        fetchNextPage: fetchNextPagePaybill,
        fetchPreviousPage: fetchPreviousPagePaybill,
        isFetchingNextPage: isFetchingNextPagePaybill,
        isFetchingPreviousPage: isFetchingPreviousPagePaybill,
    } = useInfiniteQuery<Pagination<any>>(['paybills', search], ApiClient.getPaybillTransactions, {
        refetchOnWindowFocus: false,
        getNextPageParam: (lastPage) => {
            return lastPage.nextPage;
        },
        enabled: activeTab === 'paybill-payments',
    });

    const {
        data: recentData,
        isFetching: isFetchingRecent,
        fetchNextPage: fetchNextPageRecent,
        fetchPreviousPage: fetchPreviousPageRecent,
        isFetchingNextPage: isFetchingNextPageRecent,
        isFetchingPreviousPage: isFetchingPreviousPageRecent,
    } = useInfiniteQuery<Pagination<any>>(['recent', search], ApiClient.getRecentTransactions, {
        refetchOnWindowFocus: false,
        getNextPageParam: (lastPage) => {
            return lastPage.nextPage;
        },
        enabled: activeTab === 'recent-payments',
    });

    const payments = React.useMemo(() => {
        return data?.pages.map((page) => page.data).flat();
    }, [data]);

    const paybillPayments = React.useMemo(() => {
        return paybillData?.pages.map((page) => page.data).flat();
    }, [paybillData]);

    const recentPayments = React.useMemo(() => {
        return recentData?.pages.map((page) => page.data).flat();
    }, [recentData]);

    const total = React.useMemo(() => {
        return data?.pages?.[0]?.total;
    }, [data]);

    const paybillTotal = React.useMemo(() => {
        return paybillData?.pages?.[0]?.total;
    }, [paybillData]);

    const recentTotal = React.useMemo(() => {
        return recentData?.pages?.[0]?.total;
    }, [recentData]);

    if (activeTab === 'recent-payments') {
        return (
            <PaymentComponent
                data={recentPayments}
                loading={isFetchingRecent}
                columns={Columns}
                columns2={Columns2}
                columns3={Columns3}
                columns4={Columns4}
                total={recentTotal ?? 0}
                fetchNextPage={fetchNextPageRecent}
                fetchPreviousPage={fetchPreviousPageRecent}
                isFetchingNextPage={isFetchingNextPageRecent}
                isFetchingPreviousPage={isFetchingPreviousPageRecent}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                setFetchSearch={setSearch}
            />
        );
    }

    if (activeTab === 'paybill-payments') {
        return (
            <PaymentComponent
                data={paybillPayments}
                loading={isFetchingPaybill}
                columns={Columns}
                columns2={Columns2}
                columns3={Columns3}
                columns4={Columns4}
                total={paybillTotal ?? 0}
                fetchNextPage={fetchNextPagePaybill}
                fetchPreviousPage={fetchPreviousPagePaybill}
                isFetchingNextPage={isFetchingNextPagePaybill}
                isFetchingPreviousPage={isFetchingPreviousPagePaybill}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                setFetchSearch={setSearch}
            />
        );
    }

    return (
        <PaymentComponent
            data={payments}
            loading={isFetching}
            columns={Columns}
            columns2={Columns2}
            columns3={Columns3}
            columns4={Columns4}
            total={total ?? 0}
            fetchNextPage={fetchNextPage}
            fetchPreviousPage={fetchPreviousPage}
            isFetchingNextPage={isFetchingNextPage}
            isFetchingPreviousPage={isFetchingPreviousPage}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            setFetchSearch={setSearch}
        />
    );
};

export default PaymentContainer;
